:root {
  --text-color: #57584e;
  --hover-blue: #197cd7;
  --pressed-blue: #2147a8;
  --disabled-blue: rgba(25, 124, 215, 0.7);
  --deeper-blue: #171832;
  --sky-blue: #26abc7;
  --white: #fff;
  --light-font: #8a92a6;
  --secondary-grey: #f7f7f9;
  --secondary-grey-text: #6f727a;
  --danger: #f32d2d;
  --success: #55bd68;
  --toastify-color-error: blue;
  --carton: #f7f7f9;
  --ash: #6c6e7f;
  --primary-color: #45079a;
  --light-purple: #f7f6fd;
  --lighter-purple: rgba(235, 233, 249, 0.6);
  --primary-color-black: #232325;
}

.wallet_business_name div {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e2e4e8;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #b2b7c2;
  width: 100%;
}
.wallet_business_name div p {
  margin: 0 5px;
}
.wallet_business_name div input {
  border: none !important;
  outline: none;
  padding: 7px 0px !important;
}

.action-wrap {
  display: flex;
}
.action-wrap li {
  background: #ffffff;
  border: 1px solid #e0e7ed;
  padding: 7px 12px;
  cursor: pointer;
}
.action-wrap li.active {
  background: #45079a;
  color: #ffffff;
}

.screen_size {
  width: 70%;
  margin: 30px 0;
}
@media (max-width: 820px) {
  .screen_size {
    width: 100%;
  }
}/*# sourceMappingURL=globalstyle.css.map */
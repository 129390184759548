.cancel_icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.not-wrapper {
  // border: 1px solid #e0e7ed;
  padding: 32px;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;

  > div > div {
    margin: 2rem 0;
    position: relative;

    h5 {
      font-weight: 800;
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }

    span * {
      font-weight: 500;
    }

    > div {
      max-width: 350px;
      margin: 1.5rem auto;
    }

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
      left: 0;
      bottom: -16px;
      //  bottom: 60px;
    }
  }

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  form {
    max-width: 573px;
    margin: 2rem 0 0;
  }

  .airtime-toggle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #e2e4e8;
    padding: 8px;
    margin: 80px 0 20px 0;

    .toggle-btn {
      background-color: #ffffff;
      padding: 6px;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e0e7ed;
    }
  }

  .airtime-step1-wrapper,
  .airtime-step2-wrapper {
    padding: 20px 40px;
    width: 100%;
    border: 1px solid #e0e7ed;
    border-radius: 8px;
    margin: 10px 0;
    .airtime-step1,
    .airtime-step2 {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;

      .airtime-step-functions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        color: #25233a;

        .airtime-step-back {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;
          cursor: pointer;
        }

        .airtime-step-back:disabled {
          cursor: no-drop;
          color: #e2e4e8;
        }
      }

      .airtime-response-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .response-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .header-title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #25233a;
          }

          button {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            align-items: center;
            color: #171832;
          }
        }

        .response-content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .response-content {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .single-content-row {
              display: flex;
              align-items: center;
              flex-direction: row !important;
              justify-content: space-between;
            }
            .single-content {
              display: flex;
              flex-direction: column;
              gap: 2px;

              .title {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #171832;
              }

              .details {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: #171832;
              }
            }
          }

          .airtime-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 10px;
            button {
              display: flex;
              padding: 12px 206px;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              color: #ffffff;
              background: #45079a;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

.not_input_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  // width: 10px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: #25233a;
  }

  select {
    border: 1px solid #45079a;
    border-radius: 6px;
    width: 493px;
    max-width: 100%;
  }
}

.airtime-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 10px;
  //

  button {
    display: flex;
    padding: 12px 12px;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    background: #45079a;
    border-radius: 4px;
  }
}

.display_on_sm {
  visibility: hidden;
  // small screen
  @media (max-width: 768px) {
    visibility: visible;
  }
}

.not_not_read {
  * {
    font-weight: 700;
  }
}

.cancel_icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.not-wrapper {
  padding: 32px;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
}
.not-wrapper > div > div {
  margin: 2rem 0;
  position: relative;
}
.not-wrapper > div > div h5 {
  font-weight: 800;
  font-size: 14px;
}
.not-wrapper > div > div p {
  font-size: 14px;
}
.not-wrapper > div > div span * {
  font-weight: 500;
}
.not-wrapper > div > div > div {
  max-width: 350px;
  margin: 1.5rem auto;
}
.not-wrapper > div > div:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  left: 0;
  bottom: -16px;
}
.not-wrapper h2 {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.not-wrapper form {
  max-width: 573px;
  margin: 2rem 0 0;
}
.not-wrapper .airtime-toggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #e2e4e8;
  padding: 8px;
  margin: 80px 0 20px 0;
}
.not-wrapper .airtime-toggle .toggle-btn {
  background-color: #ffffff;
  padding: 6px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #e0e7ed;
}
.not-wrapper .airtime-step1-wrapper,
.not-wrapper .airtime-step2-wrapper {
  padding: 20px 40px;
  width: 100%;
  border: 1px solid #e0e7ed;
  border-radius: 8px;
  margin: 10px 0;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1,
.not-wrapper .airtime-step1-wrapper .airtime-step2,
.not-wrapper .airtime-step2-wrapper .airtime-step1,
.not-wrapper .airtime-step2-wrapper .airtime-step2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-step-functions,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-step-functions,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-step-functions,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-step-functions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #25233a;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-step-functions .airtime-step-back,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-step-functions .airtime-step-back,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-step-functions .airtime-step-back,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-step-functions .airtime-step-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-step-functions .airtime-step-back:disabled,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-step-functions .airtime-step-back:disabled,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-step-functions .airtime-step-back:disabled,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-step-functions .airtime-step-back:disabled {
  cursor: no-drop;
  color: #e2e4e8;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-header,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-header,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-header,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-header .header-title,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-header .header-title,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-header .header-title,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-header .header-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #25233a;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-header button,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-header button,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-header button,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-header button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #171832;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content-row,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content-row,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content-row,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content-row {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  justify-content: space-between;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .title,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .title,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .title,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #171832;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .details,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .details,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .details,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .response-content .single-content .details {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #171832;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .airtime-btn,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .airtime-btn,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .airtime-btn,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .airtime-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-wrapper .airtime-step1-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .airtime-btn button,
.not-wrapper .airtime-step1-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .airtime-btn button,
.not-wrapper .airtime-step2-wrapper .airtime-step1 .airtime-response-wrapper .response-content-wrapper .airtime-btn button,
.not-wrapper .airtime-step2-wrapper .airtime-step2 .airtime-response-wrapper .response-content-wrapper .airtime-btn button {
  display: flex;
  padding: 12px 206px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  background: #45079a;
  border-radius: 4px;
}

.not_input_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.not_input_wrapper label {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #25233a;
}
.not_input_wrapper select {
  border: 1px solid #45079a;
  border-radius: 6px;
  width: 493px;
  max-width: 100%;
}

.airtime-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.airtime-btn button {
  display: flex;
  padding: 12px 12px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  background: #45079a;
  border-radius: 4px;
}

.display_on_sm {
  visibility: hidden;
}
@media (max-width: 768px) {
  .display_on_sm {
    visibility: visible;
  }
}

.not_not_read * {
  font-weight: 700;
}/*# sourceMappingURL=Header.css.map */